@tailwind base;
@tailwind components;
@tailwind utilities;

.heading-1 {
  @apply font-presicav font-normal text-[34px] lg:text-[60px];
}

.heading-2-light {
  @apply font-presicav font-normal lg:font-light text-[30px] lg:text-[52px];
}

.heading-2 {
  @apply font-presicav font-normal text-[30px] lg:text-[52px];
}

.heading-3 {
  @apply font-presicav font-normal lg:text-[36px] text-[24px];
}

.heading-4 {
  @apply font-avenir font-light text-[24px] lg:text-[48px];
}

.subhead-1 {
  @apply lg:font-avenir lg:font-normal lg:text-[36px] font-presicav font-normal text-[16px];
}

.subhead-2 {
  @apply lg:font-avenir lg:font-normal lg:text-[22px] font-presicav font-normal text-[14px];
}

.title-1 {
  @apply lg:font-presicav lg:font-normal lg:text-[18px] font-avenir font-semibold text-[18px];
}

.title-2 {
  @apply lg:font-presicav lg:font-normal lg:text-[14px] font-avenir font-normal text-[18px];
}

.title-3 {
  @apply font-avenir lg:font-semibold lg:text-[18px] font-black text-[16px];
}

.title-4 {
  @apply font-avenir font-black text-[16px];
}

.body-1 {
  @apply font-avenir lg:font-semibold text-[16px] font-normal;
}

.body-2 {
  @apply font-avenir font-normal lg:text-[16px] text-[14px];
}

.body-3 {
  @apply font-avenir font-medium lg:text-[14px] text-[12px];
}

.button {
  @apply px-6 py-2 rounded-full uppercase subhead-2;
}

.button-black {
  @apply text-white bg-black;
}

.button-black-outline {
  @apply text-black bg-white border-black focus:bg-black focus:text-white border-2;
}

.button-crimson-outline {
  @apply text-black bg-white border-crimson focus:bg-crimson focus:text-white;
}

.button-crimson {
  @apply text-white bg-crimson;
}
